import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: HSPU’s, HS Holds`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 25 HSPU’s`}</p>
    <p>{`6 Rounds of:`}</p>
    <p>{`1-D Ball Clean Over Shoulder (80/40) (RX+ 150/80)`}</p>
    <p>{`2-Rope Climbs to 15′`}</p>
    <p>{`10-GHD Situps`}</p>
    <p>{`12-Burpees`}</p>
    <p>{`Cash Out: 25 HSPU’s`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free class at 9:00 & 10:00am so bring a
friend!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      